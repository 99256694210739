import React, {useState} from 'react';
import logo from './logo.svg';
import { Container, Row, Col, Nav, NavItem, NavLink, Button, Image } from "react-bootstrap";
import {Link, useOutletContext} from "react-router-dom";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';
import { IoDocument, IoDocumentOutline } from "react-icons/io5";
import CourseTable from './CourseTable';

function Education() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                
                <section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <h1>Образование</h1>
                    </div>
                </section>

                <section className='section_light'>
                    <div className='main_container'>

                        <h2>Информация о реализуемых образовательных программах</h2>
                        <h3>Дополнительная профессиональная программа – программа повышения квалификации «Дневник самоконтроля качества жизни»</h3>
                        
                        <h4>Общая информация по программе</h4>
                        <div onClick={() => window.open("/files/14_professional_developmen_program.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Информация о программе «Дневник самоконтроля качества жизни»</p>
                        </div>
                        <p>Форма образовательной программы: очная.</p>
                        <br/><p>При реализации образовательной программы возможно при необходимости использование электронного обучения и дистанционных образовательных технологий.</p>
                        <br/><p>Информация о нормативных сроках обучения: 18 академических часов.</p>
                        <br/><p>Срок действия государственной аккредитации образовательной программы (при наличии государственной аккредитации): не требуется.</p>
                        <br/><p>Информация о языке, на котором осуществляется образование (обучение): обучение осуществляется на русском языке.</p>
                        
                        <h4>Учебные предметы, курсы, дисциплины (модули), практики, предусмотренные соответствующей образовательной программой (в ак. часах)</h4>
                        <CourseTable />
                        
                        <h4>Документы по программе</h4>
                        <div onClick={() => window.open("/files/14_professional_developmen_program.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Информация о программе «Дневник самоконтроля качества жизни»</p>
                        </div>
                        <div onClick={() => window.open("/files/15_education_program.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Учебный план, аннотации, календарный учебный график, методическая информация образовательной программы</p>
                        </div>
                        <h4>Дополнительная информация</h4>
                        <p>Информация об общей численности обучающихся по образовательной программе: обучение проходят  19 администраторов копий программы для ЭВМ «Дневник самоконтроля качества жизни».</p>
                        <br/><p>Информация о численности обучающихся по договорам об образовании, заключаемых при приёме на обучение за счёт средств физического и (или) юридического лица: обучение проводится в рамках договоров технической поддержки.</p>
                        <br/><p>Информация о численности обучающихся за счёт бюджетных ассигнований федерального бюджета (в том числе с выделением численности обучающихся, являющихся иностранными гражданами): нет данной категории обучающихся.</p>
                        <br/><p>Информация о численности обучающихся за счёт бюджетных ассигнований бюджетов субъектов Российской Федерации (в том числе с выделением численности обучающихся, являющихся иностранными гражданами): нет данной категории обучающихся.</p>
                        <br/><p>Информация о численности обучающихся за счёт бюджетных ассигнований местных бюджетов (в том числе с выделением численности обучающихся, являющихся иностранными гражданами): нет данной категории обучающихся.</p>
                        <br/><p>Информация об уровне образования обучающихся: лица, имеющие среднее профессиональное и (или) высшее образование; лица, получающие среднее профессиональное и (или) высшее образование.</p>
                        <br/><p>Информация о коде и наименовании профессии, специальности, направления подготовки, научной специальности: не требуется.</p>
                        <br/><p>Информация о направлениях и результатах научной (научно-исследовательской) деятельности и научно-исследовательской базе для её осуществления: не требуется.</p>
                        <br/><p>Информация о результатах приёма по каждому направлению подготовки или специальности высшего образования, научной специальности с различными условиями приёма (на места, финансируемые за счёт бюджетных ассигнований федерального бюджета, бюджетов субъектов Российской Федерации, местных бюджетов, по договорам об образовании за счёт средств физических и (или) юридических лиц) с указанием средней суммы набранных баллов по всем вступительным испытаниям: не требуется.</p>
                        <br/><p>Информация о результатах перевода, восстановления и отчисления: не требуется.</p>
                        <br/><p>Образовательная организация оставляет за собой право обеспечивать взаимодействие с обучающимися посредством видеоконференцсвязи.</p>
                    </div>
                </section>

                <section className='educational-activity'>
                    <div className='main_container'>
                        <div className='main_row'>
                            <Col className='white-rectangle left-rectangle'>
                                <div className="main_row home-contacts-row">
                                    <h2>Лицензия на осуществление образовательной деятельности</h2>
                                    {(fontScale==100 && getContrastClassName(contrast)=='' && showImages) && <Image src="icons/education.svg" width={50} height={50} />}
                                </div>
                                <p>Образовательная деятельность осуществляется
                                на основании Лицензии от 28 декабря 2023 г. №Л035-01298-77/01010690 на осуществление образовательной деятельности, выданной Департаментом образования и науки города Москвы.</p>
                                <p onClick={() => window.open("/files/2_license.pdf", "_blank")} className='save_doc'>Скачать документ</p>
                            </Col>
                        </div>
                    </div>
                </section>


                {/* Было задукоментировано
                     <div onClick={() => window.open("/files/text.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Информация об общей численности обучающихся по образовательной программе
                    </div> 
                    <div onClick={() => window.open("/files/text.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Информация о численности обучающихся по договорам об образовании, заключаемых при приёме на обучение за счёт средств физического и (или) юридического лица (в том числе с выделением численности обучающихся, являющихся иностранными гражданами)
                    </div> 
                    конец */}

                {/*<Container className='content'>
                    <h1 className='h1_text'>Образование</h1>
                    <p>Образовательная деятельность осуществляется на основании Лицензии от 28 декабря 2023 г. № Л035-01298-77/01010690 на осуществление образовательной деятельности, выданной Департаментом образования и науки города Москвы.</p>
                    <div onClick={() => window.open("/files/2_license.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Информация о лицензии на осуществление образовательной деятельности (выписке из реестра лицензий на осуществление образовательной деятельности)
                    </div>

                    <h2 className='h2_text'>Информация о реализуемых образовательных программах</h2>
                    <h3 className='h3_text'>Дополнительная профессиональная программа – программа повышения квалификации «Дневник самоконтроля качества жизни»</h3>
                    
                    <h4>Общая информация по программе</h4>
                    <div onClick={() => window.open("/files/14_professional_developmen_program.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Информация о программе «Дневник самоконтроля качества жизни»
                    </div>
                    <p>Форма образовательной программы: очная.</p>
                    <p>При реализации образовательной программы возможно при необходимости использование электронного обучения и дистанционных образовательных технологий.</p>
                    <p>Информация о нормативных сроках обучения: 18 академических часов.</p>
                    <p>Срок действия государственной аккредитации образовательной программы (при наличии государственной аккредитации): не требуется.</p>
                    <p>Информация о языке, на котором осуществляется образование (обучение): обучение осуществляется на русском языке.</p>
                    
                    <h4>Учебные предметы, курсы, дисциплины (модули), практики, предусмотренные соответствующей образовательной программой (в ак. часах)</h4>
                    <CourseTable />
                    
                    <h4>Документы по программе</h4>
                    <div onClick={() => window.open("/files/14_professional_developmen_program.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Информация о программе «Дневник самоконтроля качества жизни»
                    </div>
                    <div onClick={() => window.open("/files/15_education_program.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Учебный план, аннотации, календарный учебный график, методическая информация образовательной программы
                    </div>
                    {/* Было задукоментировано
                     <div onClick={() => window.open("/files/text.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Информация об общей численности обучающихся по образовательной программе
                    </div> 
                    <div onClick={() => window.open("/files/text.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Информация о численности обучающихся по договорам об образовании, заключаемых при приёме на обучение за счёт средств физического и (или) юридического лица (в том числе с выделением численности обучающихся, являющихся иностранными гражданами)
                    </div> 
                    конец
                    
                    <h4>Дополнительная информация</h4>
                    <p>Информация об общей численности обучающихся по образовательной программе: обучение проходят  19 администраторов копий программы для ЭВМ «Дневник самоконтроля качества жизни».</p>
                    <p>Информация о численности обучающихся по договорам об образовании, заключаемых при приёме на обучение за счёт средств физического и (или) юридического лица: обучение проводится в рамках договоров технической поддержки.</p>
                    <p>Информация о численности обучающихся за счёт бюджетных ассигнований федерального бюджета (в том числе с выделением численности обучающихся, являющихся иностранными гражданами): нет данной категории обучающихся.</p>
                    <p>Информация о численности обучающихся за счёт бюджетных ассигнований бюджетов субъектов Российской Федерации (в том числе с выделением численности обучающихся, являющихся иностранными гражданами): нет данной категории обучающихся.</p>
                    <p>Информация о численности обучающихся за счёт бюджетных ассигнований местных бюджетов (в том числе с выделением численности обучающихся, являющихся иностранными гражданами): нет данной категории обучающихся.</p>
                    <p>Информация об уровне образования обучающихся: лица, имеющие среднее профессиональное и (или) высшее образование; лица, получающие среднее профессиональное и (или) высшее образование.</p>
                    <p>Информация о коде и наименовании профессии, специальности, направления подготовки, научной специальности: не требуется.</p>
                    <p>Информация о направлениях и результатах научной (научно-исследовательской) деятельности и научно-исследовательской базе для её осуществления: не требуется.</p>
                    <p>Информация о результатах приёма по каждому направлению подготовки или специальности высшего образования, научной специальности с различными условиями приёма (на места, финансируемые за счёт бюджетных ассигнований федерального бюджета, бюджетов субъектов Российской Федерации, местных бюджетов, по договорам об образовании за счёт средств физических и (или) юридических лиц) с указанием средней суммы набранных баллов по всем вступительным испытаниям: не требуется.</p>
                    <p>Информация о результатах перевода, восстановления и отчисления: не требуется.</p>
                    <p>Образовательная организация оставляет за собой право обеспечивать взаимодействие с обучающимися посредством видеоконференцсвязи.</p>
                </Container>*/}
                <Footer/>
            </div>
        </div>
    )
}

export default Education;