import React, {useState} from 'react';
import logo from './logo.svg';
import { Container, Row, Col, Nav, NavItem, NavLink, Button, Image } from "react-bootstrap";
import {Link, useOutletContext} from "react-router-dom";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';

function InternationalCooperation() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                <section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <h1>Международное сотрудничество</h1>
                    </div>
                </section>

                <section className='section_light'>
                    <div className='main_container'>
                        <p>Информация о заключенных и планируемых к заключению договорах с иностранными и (или) международными организациями по вопросам образования и науки: заключенных и планируемых к заключению договоров нет.</p>
                        <br/><p>Информация о международной аккредитации образовательных программ: международная аккредитация образовательных программ отсутствует.</p>
                    </div>
                </section>

                <section className='section_blue'>
                    <div className='main_container'>
                        <Image src={showImages ? "images/international_cooperation.png" : "images/nullimginternational.png"} className="internationalcooperation-img my-3" alt="Image international cooperation" />
                    </div>
                </section>
                
                {/*<Container className='content'>
                    <h1 className='h1_text'>Международное сотрудничество</h1>
                    <Col className='internationalcooperation'>
                        <Row>
                            <p>Информация о заключенных и планируемых к заключению договорах с иностранными и (или) международными организациями по вопросам образования и науки: заключенных и планируемых к заключению договоров нет.</p>
                            <p>Информация о международной аккредитации образовательных программ: международная аккредитация образовательных программ отсутствует.</p>
                        </Row>
                        <Row>
                            <Image src={showImages ? "images/international_cooperation.png" : "images/nullimginternational.png"} className="mx-1" alt="Image international cooperation" />
                        </Row>
                    </Col>
                </Container>*/}
                <Footer/>
            </div>
        </div>
    )
}

export default InternationalCooperation;