import React, {useState} from 'react';
import logo from './logo.svg';
import { Container, Row, Col, Nav, NavItem, NavLink, Button } from "react-bootstrap";
import {Link, Outlet, useOutletContext} from "react-router-dom";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';

const BasicInfo: React.FC = () => {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
        , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
       } = contextOutlet;
    
    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                <section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <h1>ОСНОВНЫЕ СВЕДЕНИЯ</h1>
                    </div>
                </section>
                <section className='section_light'>
                    <div className='main_container'>
                        <h2>Наименование</h2>
                        <p>Автономная некоммерческая организация «Институт демографической политики имени Д. И. Менделеева».</p>
                        <br/><p>Сокращённое наименование образовательной организации: АНО «ИДП им. Д. И. Менделеева».</p>
                        <br/><p>Дата создания образовательной организации: 18 апреля 2022 г.</p>
                        <br/><p>Место нахождения образовательной организации: <a href="https://yandex.ru/maps/-/CDBeMG2Z" className="a_link" target="_blank" rel="noopener noreferrer">109012, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Никольская, д. 10, офис 601</a>.</p>   
                    </div>
                </section>
                <section className='section_light'>
                    <div className='main_container'>
                        <h2>Сведения об учредителе</h2>
                        <p>Учредитель образовательной организации: Ассоциация межрегионального социально-экономического взаимодействия «Центральный Федеральный Округ».</p>
                    </div>
                </section>
                <section className='section_light'>
                    <div className='main_container'>
                        <h2>Контакты образовательной организации</h2>
                        <p>Режим и график работы образовательной организации: ежедневно в рабочие дни с 9:00 до 18:00 по московскому времени.</p>
                        <br/><p>Контактный телефон образовательной организации: <a href="tel:+74952216991" className='a_link'>+7 (495) 221-69-91</a>.</p>
                        <br/><p>Адрес электронной почты образовательной организации: <a href="mailto:info@indemp.ru" className='a_link'>info@indemp.ru</a>.</p>
                        <br/><p>Место осуществления образовательной деятельности: <a href="https://yandex.ru/maps/-/CDBeMG2Z" className="a_link" target="_blank" rel="noopener noreferrer">109012, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Никольская, д. 10, офис 601</a>.</p>
                    </div>
                </section>
                <section className='section_light'>
                    <div className='main_container'>
                        <h2>Дополнительные сведения</h2>
                        <p>Образовательная организация не имеет представительств и филиалов.</p>
                        <br/><p>В отношении реализуемых в образовательной организации  дополнительных профессиональных программ и основных программ профессионального обучения отсутствуют федеральные государственные образовательные стандарты и образовательные стандарты.</p>
                        <br/><p>Образовательная организация оказывает услуги на коммерческой основе. В образовательной организации не предусмотрены стипендии и иные выплаты обучающимся, общежитие и интернат, трудоустройство выпускников.</p>
                    </div>
                </section>

                {/*<Container className='content'>
                    <h1 className='h1_text'>Основные сведения</h1>
                    
                    <h2 className='h2_text_first h2_text'>Наименование</h2>
                    <p>Автономная некоммерческая организация «Институт демографической политики имени Д. И. Менделеева».</p>
                    <p>Сокращённое наименование образовательной организации: АНО «ИДП им. Д. И. Менделеева».</p>
                    <p>Дата создания образовательной организации: 18 апреля 2022 г.</p>
                    <p>Место нахождения образовательной организации: <a href="https://yandex.ru/maps/-/CDBeMG2Z" className="a_link" target="_blank" rel="noopener noreferrer">109012, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Никольская, д. 10, офис 601</a>.</p>
                    
                    <h2 className='h2_text'>Сведения об учредителе</h2>
                    <p>Учредитель образовательной организации: Ассоциация межрегионального социально-экономического взаимодействия «Центральный Федеральный Округ».</p>
                    
                    <h2 className='h2_text'>Контакты образовательной организации</h2>
                    <p>Режим и график работы образовательной организации: ежедневно в рабочие дни с 9:00 до 18:00 по московскому времени.</p>
                    <p>Контактный телефон образовательной организации: <a href="tel:+74952216991" className='a_link'>+7 (495) 221-69-91</a>.</p>
                    <p>Адрес электронной почты образовательной организации: <a href="mailto:info@indemp.ru" className='a_link'>info@indemp.ru</a>.</p>
                    <p>Место осуществления образовательной деятельности: <a href="https://yandex.ru/maps/-/CDBeMG2Z" className="a_link" target="_blank" rel="noopener noreferrer">109012, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Никольская, д. 10, офис 601</a>.</p>
                    
                    <h2 className='h2_text'>Дополнительные сведения</h2>
                    <p>Образовательная организация не имеет представительств и филиалов.</p>
                    <p>В отношении реализуемых в образовательной организации  дополнительных профессиональных программ и основных программ профессионального обучения отсутствуют федеральные государственные образовательные стандарты и образовательные стандарты.</p>
                    <p>Образовательная организация оказывает услуги на коммерческой основе. В образовательной организации не предусмотрены стипендии и иные выплаты обучающимся, общежитие и интернат, трудоустройство выпускников.</p>

                </Container>*/}
                <Footer/>
            </div>
        </div>
    )
}

export default BasicInfo;