import React, { useEffect, useState } from 'react';
import {Link, useOutletContext} from "react-router-dom";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';
import axios from 'axios';
import Footer from './Footer';

type TypeManager = 'BOARD_TRUSTEES' | 'STAFF' | 'TEACHING_STAFF'

interface IManager {
    id: number,
    fio: string,
    position: string,
    type: TypeManager,
    s3Key: string,
    bucket: string,
    mime: string,
    comment: string | null
}

const Managers = () => {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    const [managers, setManagers] = useState<IManager[]>([]);

    const [boardTrustees, setBoardTrustees] = useState<IManager[]>([]);
    const [staff, setStaff] = useState<IManager[]>([]);
    const [teachingStaff, setTeachingStaff] = useState<IManager[]>([]);


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');

    console.log('window.config ', window.config?.API_BASE_URL);
    const API_BASE_URL = window.config?.API_BASE_URL ?? 'http://localhost:8080';

    useEffect(() => {
        const fetchManagers = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/managers`);
                setManagers(response.data); // Получаем записи из ответа
                setBoardTrustees(response.data.filter((manager: IManager) => manager.type === 'BOARD_TRUSTEES'));
                setStaff(response.data.filter((manager: IManager) => manager.type === 'STAFF'));
                setTeachingStaff(response.data.filter((manager: IManager) => manager.type === 'TEACHING_STAFF'));
            } catch (err) {
                if (axios.isAxiosError(err)) {
                // Это ошибка Axios
                setError(`Ошибка при загрузке данных: ${err.response?.data?.message || err.message}`);
                } else {
                // Любая другая ошибка
                setError('Ошибка при загрузке данных');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchManagers();
    }, []);

    // Функция для разбивки массива на подмассивы с заданной длиной
    const chunkArray = (array: any[], size: number) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };
    const width = window.innerWidth;
    const size = width > 992 ? 3 : width > 576 ? 2 : 1;

    // Компонент для отображения руководства
    const renderTeachers = (managers: IManager[], showImages: boolean, size: number) => {
        return (
            <div className="teachers">
                {chunkArray(managers, size).map((row, rowIndex) => (
                    <div key={rowIndex} className={`teachers-row ${row.length === 2 ? 'two' : row.length === 1 ? 'one' : ''}`}>
                        {row.map((manager: IManager) => (
                            <div className={`teacher ${row.length === 2 ? 'two' : row.length === 1 ? 'one' : ''}`} key={manager.fio}>
                                <img 
                                    src={showImages ? `${API_BASE_URL}/api/manager-image/${manager.s3Key}` : 'images/nullteacher.png'} 
                                    alt="" 
                                    style={{ marginTop: '0px' }}
                                />
                                <div className='teacher_text'>
                                    <h2>{manager.fio}</h2>
                                    <p>{manager.position}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    if (loading) {
        return <span className="loader"></span>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                <section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <h1>РУКОВОДСТВО</h1>
                    </div>
                </section>

                <section className='section_light'>
                    <div className='main_container'>
                        <h2>Попечительский совет</h2>
                        <p>Попечительский совет – коллегиальный орган, проводящий общественную экспертизу проектов и инициатив Института демографической политики имени Д. И. Менделеева.</p>
                    </div>
                </section>

                <section className='section_blue'>
                    <div className='main_container'>
                        {renderTeachers(boardTrustees, showImages, size)}
                    </div>
                </section>
                    

                <section className='section_light'>
                    <div className='main_container'>
                        <h2 className='h2_only'>Сотрудники</h2>
                    </div>
                </section>

                <section className='section_blue'>
                    <div className='main_container'>
                        {renderTeachers(staff, showImages, size)}
                    </div>
                </section>


                <section className='section_light'>
                    <div className='main_container'>
                        <h2 className='h2_only'>Педагогические работники</h2>
                    </div>
                </section>

                <section className='section_blue'>
                    <div className='main_container'>
                        {renderTeachers(teachingStaff, showImages, size)}
                    </div>
                </section>

        
                <Footer/>
            </div>
        </div>
    );
};


export default Managers;
