import React, {useState} from 'react';
import logo from './logo.svg';
import { Container, Row, Col, Nav, NavItem, NavLink, Button, Image } from "react-bootstrap";
import {Link, useOutletContext} from "react-router-dom";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';

function PlacesAdmission() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                <section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <h1>Вакантные места для приёма (перевода) обучающихся</h1>
                    </div>
                </section>

                <section className='section_light'>
                    <div className='main_container'>
                        <p>Зачисление в группы проводится по заявлениям от физических или юридических лиц без вступительных испытаний.</p>
                        <br/><p>При поступлении на обучение в образовательную организацию заключается договор об оказании платных образовательных услуг.</p>
                        <br/><p>Приём на обучение в АНО «ИДП им. Д. И. Менделеева» проводится в соответствии с возможной наполняемостью группы. </p>
                        <br/><p>Набор в группы ведётся в течение всего календарного года.</p>
                        <br/><p>Вакантных мест для приёма на обучение за счёт государственного бюджета не предусмотрено.</p>
                        <br/><p>АНО «ИДП им. Д. И. Менделеева» предоставляет услуги на коммерческой основе.</p>
                    </div>
                </section>

                {/*<section className='section_blue'>
                    <div className='main_container placesadmission'>
                        <Image src={showImages ? "images/places_admission.png" : "images/nullimgplaceadmission.png"} className="my-3" />
                    </div>
                </section>*/}
                
                {/*<Container className='content'>
                    <h1 className='h1_text'>Вакантные места для приёма (перевода) обучающихся</h1>
                    <Row className='placesadmission'>
                        <Col md={12} lg={6}>
                            <p>Зачисление в группы проводится по заявлениям от физических или юридических лиц без вступительных испытаний.</p>
                            <p>При поступлении на обучение в образовательную организацию заключается договор об оказании платных образовательных услуг.</p>
                            <p>Приём на обучение в АНО «ИДП им. Д. И. Менделеева» проводится в соответствии с возможной наполняемостью группы. </p>
                            <p>Набор в группы ведётся в течение всего календарного года.</p>
                            <p>Вакантных мест для приёма на обучение за счёт государственного бюджета не предусмотрено.</p>
                            <p>АНО «ИДП им. Д. И. Менделеева» предоставляет услуги на коммерческой основе.</p>
                        </Col>
                        <Col md={12} lg={6}>
                            <Image src={showImages ? "images/places_admission.png" : "images/nullimgplaceadmission.png"} className="mx-1" />
                        </Col>
                    </Row>
                </Container>*/}
                <Footer/>
            </div>
        </div>
    )
}

export default PlacesAdmission;