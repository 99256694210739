import React, {useState} from 'react';
import logo from './logo.svg';
import { Container, Row, Col, Nav, NavItem, NavLink, Button } from "react-bootstrap";
import {Link, useOutletContext} from "react-router-dom";
import { IoDocument } from "react-icons/io5";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';

function Structure() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;
     
    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
            <section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <h1>Структура и органы управления образовательной организацией</h1>
                    </div>
                </section>
                <section className='section_light'>
                    <div className='main_container'>
                        <h2>Структура</h2>
                        <p>Коллегиальный высший орган управления образовательной организации: Общее собрание Учредителя.</p>
                        <br/><p>Постоянно действующий коллегиальный орган управления образовательной организации: Наблюдательный совет.</p>
                        <br/><p>Коллегиальный орган, проводящий общественную экспертизу проектов и инициатив образовательной организации: Попечительский совет.</p>
                        <br/><p>Единоличный исполнительный орган образовательной организации: исполнительный директор.</p>
                        <br/><p>Структурное подразделение по осуществлению образовательной деятельности в организации: учебный центр.</p>
                        <br/><p>Руководителем учебного центра является исполнительный директор образовательной организации: Ефимов Иван Павлович.</p>   
                    </div>
                </section>
                <section className='section_light'>
                    <div className='main_container'>
                        <h2>Адреса образовательной организации</h2>
                        <p>Место нахождения образовательной организации, в том числе структурного подразделения «Учебный центр»: <a href="https://yandex.ru/maps/-/CDBeMG2Z" className="a_link" target="_blank" rel="noopener noreferrer">109012, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Никольская, д. 10, офис 601</a>.</p>
                        <br/><p>Адрес официального сайта образовательной организации, в том числе структурного подразделения «Учебный центр», в информационно-телекоммуникационной сети «Интернет»: <a href="/" className='a_link'>индемп.рф</a>.</p>
                        <br/><p>Адрес электронной почты образовательной организации, в том числе структурного подразделения «Учебный центр»: <a href="mailto:info@indemp.ru" className='a_link'>info@indemp.ru</a>.</p>   
                    </div>
                </section>

                <section className='section_light'>
                    <div className='main_container'>
                        <h2>Положение</h2>
                        <div onClick={() => window.open("/files/16_regulation_on_the_UC.pdf", "_blank")} className='icon_div'>
                            <IoDocument className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                            <p className='icon_text'>Положение об учебном центре автономной некоммерческой организации «Институт демографической политики имени Д. И. Менделеева»</p>
                        </div>   
                    </div>
                </section>
                {/*<Container className='content'>
                    <h1 className='h1_text'>Структура и органы управления образовательной организацией</h1>

                    <h2 className='h2_text_first h2_text'>Структура</h2>
                    <p>Коллегиальный высший орган управления образовательной организации: Общее собрание Учредителя.</p>
                    <p>Постоянно действующий коллегиальный орган управления образовательной организации: Наблюдательный совет.</p>
                    <p>Коллегиальный орган, проводящий общественную экспертизу проектов и инициатив образовательной организации: Попечительский совет.</p>
                    <p>Единоличный исполнительный орган образовательной организации: исполнительный директор.</p>
                    <p>Структурное подразделение по осуществлению образовательной деятельности в организации: учебный центр.</p>
                    <p>Руководителем учебного центра является исполнительный директор образовательной организации: Ефимов Иван Павлович.</p>
                    
                    <h2 className='h2_text'>Адреса образовательной организации</h2>
                    <p>Место нахождения образовательной организации, в том числе структурного подразделения «Учебный центр»: <a href="https://yandex.ru/maps/-/CDBeMG2Z" className="a_link" target="_blank" rel="noopener noreferrer">109012, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Никольская, д. 10, офис 601</a>.</p>
                    <p>Адрес официального сайта образовательной организации, в том числе структурного подразделения «Учебный центр», в информационно-телекоммуникационной сети «Интернет»: <a href="/" className='a_link'>индемп.рф</a>.</p>
                    <p>Адрес электронной почты образовательной организации, в том числе структурного подразделения «Учебный центр»: <a href="mailto:info@indemp.ru" className='a_link'>info@indemp.ru</a>.</p>
                    
                    <h2 className='h2_text'>Положение</h2>
                    <div onClick={() => window.open("/files/16_regulation_on_the_UC.pdf", "_blank")} className='icon_div'>
                        <IoDocumentOutline className={`icon_doc ${getContrastClassName(contrast) !== "" ? getContrastClassName(contrast) : 'default_icon_color'}`} />
                        <span className='icon_text'>Положение об учебном центре автономной некоммерческой организации «Институт демографической политики имени Д. И. Менделеева»</span>
                    </div>
                </Container>*/}
            <Footer/>
            </div>
        </div>
    )
}

export default Structure;