import { createBrowserRouter } from "react-router-dom";
import Menu from "./components/Menu";
import HomePage from "./components/HomePage";
import Structure from "./components/Structure";
import BasicInfo from "./components/BasicInfo";
import Document from "./components/Document";
import Education from "./components/Education";
import InternationalCooperation from "./components/InternationalCooperation";
import AccessibleEnvironment from "./components/AccessibleEnvironment";
import FinancialActivity from "./components/FinancialActivity";
import Equipment from "./components/Equipment";
import Guide from "./components/Guide";
import PaidService from "./components/PaidService";
import PlacesAdmission from "./components/PlacesAdmission";
import Managers from "./components/Managers";


const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <Menu />,
            children: [
                {
                    path: "/",
                    element: <HomePage />,
                    index: true
                },
                {
                    path: "/organization",
                    element: <BasicInfo />,
                },
                {
                    path: "/structure",
                    element: <Structure />,
                },
                {
                    path: "/document",
                    element: <Document />,
                },
                {
                    path: "/education",
                    element: <Education />,
                },
                {
                    path: "/paidservice",
                    element: <PaidService />,
                },
                {
                    path: "/placesadmission",
                    element: <PlacesAdmission />,
                },
                //{
                //    path: "/guide",
                //    element: <Guide />,
                //},
                {
                    path: "/managers",
                    element: <Managers />,
                },
                {
                    path: "/equipment",
                    element: <Equipment />,
                },
                {
                    path: "/financialactivity",
                    element: <FinancialActivity />,
                },
                {
                    path: "/accessibleenvironment",
                    element: <AccessibleEnvironment />,
                },
                {
                    path: "/internationalcooperation",
                    element: <InternationalCooperation />,
                }
            ]
        }
    ]
)

export default router