import React, {useState} from 'react';
import logo from './logo.svg';
import { Container, Row, Col, Nav, NavItem, NavLink, Button, Image } from "react-bootstrap";
import {Link, useOutletContext} from "react-router-dom";
import Menu, { AccessibilityState, getContrastClassName } from './Menu';

import Footer from './Footer';

function Equipment() {
    const contextOutlet: AccessibilityState = useOutletContext() 
    const { showAccessibilityOptions, fontScale, contrast, showImages
      , setFontScale, setContrast, setShowAccessibilityOptions, setShowImages
     } = contextOutlet;

    return(
        <div>
            <div style={{ fontSize: fontScale + '%' }} className={`${getContrastClassName(contrast)} page-wrapper`}>
                
                <section className='section_blue'>
                    <div className='main_container_with_h1 main_container'>
                        <h1>Материально-техническое обеспечение и оснащённость образовательного процесса</h1>
                    </div>
                </section>
                
                <section className='section_light'>
                    <div className='main_container'>
                        <p>Место осуществления образовательной деятельности в соответствии с частью 4 статьи 91 Федерального закона от 29 декабря 2012 г. № 273-ФЗ «Об образовании в Российской Федерации»: <a href="https://yandex.ru/maps/-/CDBeMG2Z" className="a_link" target="_blank" rel="noopener noreferrer">109012, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Никольская, д. 10, офис 601</a>.</p>
                        <br/><p>Учебные кабинеты АНО «ИДП им. Д. И. Менделеева» оборудованы столами, стульями, столом преподавателя, стулом преподавателя, компьютером, ТВ-экраном, магнитной доской, стеллажом для хранения учебного и дидактического материала.</p>
                    </div>
                </section>

                <section className='section_blue'>
                    <div className='main_container equipment'>
                        <Image src={showImages ? "images/equipment_1.png" : "images/nullimg.png"} />
                        <Image src={showImages ? "images/equipment_2.png" : "images/nullimg.png"}/>
                        <Image src={showImages ? "images/equipment_3.png" : "images/nullimg.png"}/>
                    </div>
                </section>

                <section className='section_light'>
                    <div className='main_container'>
                        <p>В здании образовательной организации обеспечен доступ для инвалидов и лиц с ограниченными возможностями здоровья.</p>
                        <br/><p>Информация о наличии объектов для проведения практических занятий: не требуется.</p>
                        <br/><p>Сведения о наличии библиотек, объектов спорта, средств обучения и воспитания: не требуются.</p>
                        <br/><p>Сведения об условиях питания и охраны здоровья обучающихся: питание обучающихся обеспечено в учреждениях общественного питания, расположенных на первом этаже офисного центра, где находится образовательная организация (г. Москва, ул. Никольская, д. 10). На занятиях преподавателями доводится информация, направленная на формирование здорового образа жизни.</p>
                        <br/><p>Сведения о доступе к информационным системам и информационно-телекоммуникационным сетям: не требуется. Имеется оборудование и мощности для обеспечения взаимодействия с обучающимися посредством видеоконференцсвязи (по необходимости).  </p>
                        <br/><p>Сведения об электронных образовательных ресурсах, к которым обеспечивается доступ обучающихся: не требуется.</p>
                    </div>
                </section>

                
                {/*<Container className='content'>
                    <h1 className='h1_text'>Материально-техническое обеспечение и оснащённость образовательного процесса</h1>
                    <Row>
                        <p>Место осуществления образовательной деятельности в соответствии с частью 4 статьи 91 Федерального закона от 29 декабря 2012 г. № 273-ФЗ «Об образовании в Российской Федерации»: <a href="https://yandex.ru/maps/-/CDBeMG2Z" className="a_link" target="_blank" rel="noopener noreferrer">109012, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Никольская, д. 10, офис 601</a>.</p>
                        <br/><p>Учебные кабинеты АНО «ИДП им. Д. И. Менделеева» оборудованы столами, стульями, столом преподавателя, стулом преподавателя, компьютером, ТВ-экраном, магнитной доской, стеллажом для хранения учебного и дидактического материала.</p>
                    </Row>
                    <Row className='equipment_img'>
                        <Col xs={12} md={4}>
                            <Image src={showImages ? "images/equipment_1.png" : "images/nullimg.png"} />
                        </Col>
                        <Col xs={12} md={4}>
                            <Image src={showImages ? "images/equipment_2.png" : "images/nullimg.png"}/>
                        </Col>
                        <Col xs={12} md={4}>
                            <Image src={showImages ? "images/equipment_3.png" : "images/nullimg.png"}/>
                        </Col>
                    </Row>
                    <Row>
                        <p>В здании образовательной организации обеспечен доступ для инвалидов и лиц с ограниченными возможностями здоровья.</p>
                        <br/><p>Информация о наличии объектов для проведения практических занятий: не требуется.</p>
                        <br/><p>Сведения о наличии библиотек, объектов спорта, средств обучения и воспитания: не требуются.</p>
                        <br/><p>Сведения об условиях питания и охраны здоровья обучающихся: питание обучающихся обеспечено в учреждениях общественного питания, расположенных на первом этаже офисного центра, где находится образовательная организация (г. Москва, ул. Никольская, д. 10). На занятиях преподавателями доводится информация, направленная на формирование здорового образа жизни.</p>
                        <br/><p>Сведения о доступе к информационным системам и информационно-телекоммуникационным сетям: не требуется. Имеется оборудование и мощности для обеспечения взаимодействия с обучающимися посредством видеоконференцсвязи (по необходимости).  </p>
                        <br/><p>Сведения об электронных образовательных ресурсах, к которым обеспечивается доступ обучающихся: не требуется.</p>
                    </Row>
                </Container>*/}
                <Footer/>
            </div>
        </div>
    )
}

export default Equipment;